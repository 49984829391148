<template>
  <div class="contacts">
    <v-container fluid>
      <v-row style="background: linear-gradient(90deg, rgba(227,255,231,.6) 0%, rgba(217,231,255,.69) 100%);">
        <div class="title-card" style="position: relative">
          <v-col cols="12" sm="8" md="6">
            <div
              style="font-size: 2.5rem; font-weight: 600; line-height: 3.2rem; text-align: left; margin-bottom: 34px"
            >
              Контактна інформація
            </div>
            <div style="text-align: left; font-size: 1.2rem">
              Якщо у Вас є запитання або зауваження, або Вам необхідна консультація - телефонуйте.
              Наша служба підтримка з радістю Вас проконсультує.
            </div>
            <div style="text-align: left; font-size: 1rem; font-weight: 600; margin-top: 20px;">
              Будні дні: 09:00 - 18:30
            </div>
          </v-col>
          <div style="position: absolute; right: 0; bottom: 0; top: 0" v-if="$vuetify.breakpoint.smAndUp">
            <img :src="`${$publicPath}img/images/info_2.png`" style="height: 100%; max-width: 500px; opacity: .7; filter: hue-rotate(-100deg);"/>
          </div>
        </div>
      </v-row>
    </v-container>
    <v-container fluid>
      <v-row>
        <v-col cols="12">
          <div style="font-weight: bold; font-size: 3rem; margin-bottom: 0; margin-top: 30px; margin-bottom: 30px;">А куди звертатись?</div>
          <div style="max-width: 900px; margin: 0 auto;">
            <v-row>
              <v-col cols="12" md="6">
                <v-card href="tel:+380965051535" target="_blank" class="pa-2" min-height="84">
                  <v-list-item>
                    <v-list-item-icon>
                      <v-icon size="36" color="success">mdi-cellphone</v-icon>
                    </v-list-item-icon>
                    <v-list-item-content style="font-size: 1rem">
                      +38 (096) 505-15-35
                    </v-list-item-content>
                  </v-list-item>
                </v-card>
              </v-col>
              <v-col cols="12" md="6">
                <v-card href="tel:+380967070047" target="_blank" class="pa-2" min-height="84">
                  <v-list-item>
                    <v-list-item-icon>
                      <v-icon size="36" color="success">mdi-cellphone</v-icon>
                    </v-list-item-icon>
                    <v-list-item-content style="font-size: 1rem">
                      +38 (096) 707-00-47
                    </v-list-item-content>
                  </v-list-item>
                </v-card>
              </v-col>
              <v-col cols="12" md="6">
                <v-card href="mailto:tovvinksoft@gmail.com" target="_blank" class="pa-2" min-height="84">
                  <v-list-item>
                    <v-list-item-icon>
                      <v-icon size="36" color="success">mdi-email-mark-as-unread</v-icon>
                    </v-list-item-icon>
                    <v-list-item-content style="font-size: 1rem">
                      tovvinksoft@gmail.com
                    </v-list-item-content>
                  </v-list-item>
                </v-card>
              </v-col>
              <v-col cols="12" md="6">
                <v-card href="mailto:support@vinksoft.com.ua" target="_blank" class="pa-2" min-height="84">
                  <v-list-item>
                    <v-list-item-icon>
                      <v-icon size="36" color="success">mdi-email-mark-as-unread</v-icon>
                    </v-list-item-icon>
                    <v-list-item-content style="font-size: 1rem">
                      support@vinksoft.com.ua
                    </v-list-item-content>
                  </v-list-item>
                </v-card>
              </v-col>
              <v-col cols="12">
                <v-card>
                  <iframe
                    src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d647.1735619411824!2d25.59253280634904!3d49.546922521071465!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x473036b10f68bcc9%3A0x7882aa710bf647f0!2z0LLRg9C70LjRhtGPINCc0LjRgtGA0L7Qv9C-0LvQuNGC0LAg0KjQtdC_0YLQuNGG0YzQutC-0LPQviwgMjAsINCi0LXRgNC90L7Qv9GW0LvRjCwg0KLQtdGA0L3QvtC_0ZbQu9GM0YHRjNC60LAg0L7QsdC70LDRgdGC0YwsIDQ2MDAy!5e0!3m2!1suk!2sua!4v1656770602701!5m2!1suk!2sua"
                    width="100%" height="300" style="border:0;" allowfullscreen="" loading="lazy"
                    referrerpolicy="no-referrer-when-downgrade"></iframe>
                </v-card>
              </v-col>
            </v-row>
          </div>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
export default {
  name: 'Contacts'
}
</script>

<style scoped>

</style>
